body {
  background-color: rgb(33, 33, 33) !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

.event_body {
  .accordion {
    margin-bottom: 15px;

    .accordion-item {
      background: #212121;

      .accordion-header {
        .header_wrap {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 35%;
          position: absolute;
          right: 85px;
        }

        .accordion-button {
          display: flex;
          align-items: center;
          justify-content: space-between;
          position: relative;
          background: #444;
          color: #fff;
          height: 120px;
        }
      }
    }
  }
}

.toast {
  .toast-header {
    width: 100%;
    justify-content: space-between;
    position: relative;
    padding: 0;

    .btn-close {
      position: absolute;
      right: 15px;
      top: 12px;
    }
  }
}

.toast.bg-success {
  .toast-body {
    color: #fff;
  }
}

.login_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  box-shadow: -2px -2px 16px #333, 4px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 25px;
  background: rgb(33, 33, 33);
}

.sidebar-wrapper {
  background-color: #000;

  .sidebar-header {
    .logo {
      a {
        color: #d92b4c;
      }
    }
  }

  .menu {
    .sidebar-link {
      color: #fff;
    }

    .sidebar-title {
      color: #fff;
      font-size: 1rem;
      font-weight: 600;
      list-style: none;
      margin: 1.5rem 0 1rem;
      padding: 0 1rem;
      opacity: 0.5;
    }
  }
}

.sidebar-wrapper .menu .sidebar-link i,
.sidebar-wrapper .menu .sidebar-link svg {
  color: #fff;
}

.page-heading {
  h3 {
    color: #d92b4c;
  }
}

.card {
  background-color: #000;
}

.form-check-input:checked {
  background-color: #d92b4c;
  border-color: #d92b4c;
}

.modal-content {
  background-color: #444;
}

.btn.btn-primary {
  background-color: #d92b4c;
  border-color: #d92b4c;
  color: #fff;
}

.form-select {
  background-color: #444;
  border: 1px solid #444;
  color: #fff;
}

.sidebar-wrapper .menu .sidebar-link:hover {
  background-color: #444;
}

.sidebar-wrapper .menu .sidebar-item .sidebar-link.active {
  background-color: #d92b4c;
}

.form-label,
.form-check-label {
  color: #fff;
}

.form-control {
  background-color: #333;
  border: 1px solid #333;
}

.form-floating>.form-select {
  background: #333;
}

.form-floating>label {
  color: #fff;
}

#main {
  padding: 0;

  .wrapper {
    padding: 20px;
  }
}

.bg-dark {
  background-color: #111 !important;
}

.user_nav {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.navbar {
  .input-group {
    max-width: 500px;

    .input-group-text {
      background-color: #d92b4c;
      border: 1px solid #d92b4c;
      border-radius: 0.25rem;
      color: #fff;
      cursor: pointer;
    }
  }
}

.series_value_set {
  .form-control {
    color: #fff;

    &:focus {
      background-color: #000;
    }
  }
}

.pagination {
  li {
    padding: 5px 10px;

    a {
      color: #fff;
      font-size: 20px;
    }
  }

  .active {
    a {
      color: #d92b4c;
    }
  }
}

.form-control {
  color: #fff;
}

.tb_tt {
  ul {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    width: 100%;

    li {
      text-align: center;
      border: 1px solid #7d7d7d;

      width: 33.333%;

      p {
        font-size: 12px;
        padding: 5px;
        margin-bottom: 0;

        background: #000;
      }
    }

    .back {
      background: #90caf9;
    }

    .lay {
      background: #d92b4c;
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #777;
}

.form-control.error {
  border-color: red;
}


.setting_wrap {
  margin-top: 20px;

  .card {
    background-color: #333;

    .form-switch {
      padding-left: 2.5em;
      display: flex;
      align-items: center;
    }

    .form-check-label {
      font-size: 20px;
      margin-left: 10px;
    }
  }
}


.head_options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid;
  padding-bottom: 20px;
}

.event_body {
  min-height: 400px;
}

.loader_wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}

.nav-tabs {
  border-bottom: 0;

  .nav-item {
    .nav-link {
      color: #fff;
    }

    .nav-link.active {
      background-color: #d92b4c;
      color: #fff;
      border-bottom: 0;
      border-radius: 20px;

      &:after {
        display: none;
      }
    }
  }
}

.mobile-sidebar-hide {
  display: none !important;
}

.mobile-sidebar-show {
  display: none !important;
}

.mobile-hide {
  display: none !important;
}

@media screen and (max-width: 900px) {
  #sidebar {
    display: none !important;
  }

  .mobile-hide {
    display: block !important;
  }

  .overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30%;
  }



  .mobile-sidebar-show {
    display: block !important;

    #sidebar {
      display: block !important;
    }
  }

  .mobile-sidebar-hide {
    display: none !important;

    #sidebar {
      display: none !important;
    }
  }



}